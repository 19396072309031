import { ThemeProvider } from '@mui/system';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AdminPage from './components/Pages/AdminPage/AdminPage';
import AdmissionPage from './components/Pages/AdmissionPage/AdmissionPage';
import LoginPage from './components/Pages/LoginPage/LoginPage';
import WelcomePage from './components/Pages/WelcomePage/WelcomePage';

import { appTheme } from './themes/light';


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={appTheme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/admissions" element={<AdmissionPage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;