/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import Button from '@mui/material/Button';

import { Divider, Drawer, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

import LoginIcon from '@mui/icons-material/Login';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import styles from './ResponsiveAppBar.module.css';


import { useSelector, useDispatch } from 'react-redux';
import { selectId, selectLogged } from '../../../features/users/userSlice';
import { useNavigate } from 'react-router-dom';
import useToken from '../../../hooks/useToken';




const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);


  const dispatch = useDispatch();
  const islogged = useSelector(selectLogged);
  const userId = useSelector(selectId);

  const [token, setToken, validateToken] = useToken();
  const navigate = useNavigate();


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClickAdmin = () => {
    setAnchorElNav(null);
    navigate("/admin");
  }

  const handleClickIngreso = () => {
    setAnchorElNav(null);
    navigate("/admissions");
  }

  const handleClickLogin = () => {
    setAnchorElNav(null);
    navigate("/login");
  }

  const handleClickLogout = () => {
    setToken("invalid");
  }

  const handleNavigate = (url) => {
    navigate(url);
  }

  return (
    <AppBar position="sticky">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} className={styles.logo}>
            <img src="/media/img/logos/Lion.svg" alt="LOGO" />
          </Icon>
          <Button onClick={(e) => handleNavigate("/")} sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'black',
                textDecoration: 'none',
              }}
            >
              POWERFIT
            </Typography>
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              id={styles.leftMenu}
              anchor="left"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Typography variant="h6" noWrap sx={{
                bgcolor: 'secondary.main',
                paddingY: "4px",
                textAlign: 'center',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'white',
                textDecoration: 'none',
              }}
              >MENU</Typography>
              <List sx={{
                minWidth: "200px",
                maxWidth: "200px",
              }}>
                {islogged ?
                  <>
                    <ListItem disablePadding onClick={handleClickAdmin}>
                      <ListItemButton >
                        <ListItemIcon >
                          <SupervisorAccountIcon />
                        </ListItemIcon>
                        <ListItemText primary="Administrador" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding onClick={handleClickLogout}>
                      <ListItemButton >
                        <ListItemIcon >
                          <LoginIcon />
                        </ListItemIcon>
                        <ListItemText primary="Cerrar Sesión" />
                      </ListItemButton>
                    </ListItem>
                  </>
                  :
                  <ListItem disablePadding onClick={handleClickLogin}>
                    <ListItemButton >
                      <ListItemIcon >
                        <LoginIcon />
                      </ListItemIcon>
                      <ListItemText primary="Ingresar" />
                    </ListItemButton>
                  </ListItem>
                }
              </List>
            </Drawer>
          </Box>
          <Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} className={styles.logo}>
            <img src="/media/img/logos/Lion.svg" alt="LOGO" />
          </Icon>
          <Typography
            variant="h6"
            noWrap
            onClick={(e) => handleNavigate("/")}
            sx={{
              mr: 2,
              fontFamily: 'monospace',
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            POWERFIT
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {
              islogged ?
                <>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Button onClick={handleClickAdmin} sx={{ my: 2, color: 'black', display: 'block' }}>
                    Administrador
                  </Button>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Button onClick={handleClickIngreso} sx={{ my: 2, color: 'black', display: 'block' }}>
                    Control de Ingreso
                  </Button>
                  <Divider orientation="vertical" variant="middle" flexItem />
                </> : null}
          </Box>
          {
            islogged ?
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'block' } }}>
                <Button variant="contained" color="secondary" onClick={handleClickLogout}>Cerrar Sesión</Button>
              </Box>
              :
              <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'block' } }}>
                <Button variant="contained" color="secondary" onClick={handleClickLogin}>Ingresar</Button>
              </Box>
          }

        </Toolbar>
      </Container>
    </AppBar >
  );
};
export default ResponsiveAppBar;
