import { Box, Card, CardContent, Divider, Typography } from "@mui/material"


const today = () => {
    let date = new Date();

    let day = date.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;
    return today;
}

const nextMonth = (obj) => {
    let date = new Date(obj);

    date.setMonth(date.getMonth() + 1);
    let day = date.getDate();
    if (day < 10) {
        day = "0" + day;
    }
    let month = date.getMonth() + 1;
    if (month < 10) {
        month = "0" + month;
    }
    let year = date.getFullYear();
    let today = `${year}-${month}-${day}`;


    return today;
}

const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


const Invoice = (props) => {

    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="div" sx={{ fontSize: 20 }}>
                    Facturación POWERFIT
                </Typography>
                <Divider />

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "baseline", mt: 2 }}>
                    <Typography variant="h6" sx={{ fontSize: 12 }}>Expedición:&nbsp;</Typography> <Typography variant="body" sx={{ fontSize: 12 }}>{today()}</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                    <Typography variant="h6" sx={{ fontSize: 16 }}>Cliente:&nbsp;</Typography> <Typography variant="body" sx={{ fontSize: 16 }}>{props.firstName + " " + props.lastName}</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                    <Typography variant="h6" sx={{ fontSize: 16 }}>Identificación:&nbsp;</Typography> <Typography variant="body" sx={{ fontSize: 16 }}>{props.id}</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                    <Typography variant="h6" sx={{ fontSize: 16 }}>Plan:&nbsp;</Typography> <Typography variant="body" sx={{ fontSize: 16 }}>{props.plan}</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "baseline" }}>
                    <Typography variant="h6" sx={{ fontSize: 16 }}>Próximo Pago:&nbsp;</Typography> <Typography variant="body" sx={{ fontSize: 16 }}>{nextMonth(props.registeredPay)}</Typography>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "baseline" }}>
                    <Typography variant="h6" sx={{ fontSize: 16 }}>Valor:&nbsp;</Typography> <Typography variant="body" sx={{ fontSize: 16 }}>${numberWithCommas(props.price)}</Typography>
                </Box>

                <Divider />
                <Typography variant="body1" color="text.secondary" sx={{ fontSize: 12 }}>
                    Recibo generado automaticamente, ante cualquier inquietud comunicarse con la administración
                </Typography>

            </CardContent>
        </Card>
    )
}

export default Invoice;